
  import { defineComponent } from "vue";
  import Button from "primevue/button";
  import _ from "underscore";
  import * as FacebookServices from "../api/helpers/Facebook";

  export default defineComponent({
    name: "Fintonic",
    components: {
      Button,
    },
    setup() {
      let data = JSON.parse(sessionStorage.getItem("data") || '{}');
      return { data };
    },
    beforeCreate() {
      if (_.isEmpty(this.data))
        this.$router.push("/aprobado");
    },
    mounted() {
      FacebookServices.pageView();
      document.title = process.env.VUE_APP_TITLE + " | Prestamos Aprobado";
      window.scrollTo(0, 0);
      try {
        window.dataLayer?.push({ event: "FireFintonic" });
      } catch (e) {
        this.$router.push("/aprobado");
      }
    },
  });
